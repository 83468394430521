/* Layout container */
.layout {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: visible; /* Allow inner content to scroll */
}

/* Fixed background styling */
.fixed-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1; /* Ensure it's behind everything */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Keeps the background fixed during scroll */
}

/* Overlay for optional darkening */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Below content, above background */
  background-color: rgba(0, 0, 0, 0.5); /* Optional semi-transparent dark overlay */
}

/* Scrollable content container */
.content-container {
  position: relative;
  z-index: 2; /* Above overlay and background */
  overflow-y: scroll; /* Ensure scrolling works */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  min-height: 100vh; /* Ensure content takes up full height */
  animation: fadeIn 1s ease-in-out;
  scroll-behavior: smooth; /* Ensures smooth scroll for JS interactions */
}

/* Hide scrollbars for better UI */
.content-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari */
}

/* Optional fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Ensure body and html allow scrolling */
html, body {
  height: 100%;
  margin: 0;
  overflow: visible; /* Ensure scrolling behavior for JS works */
  scroll-behavior: smooth; /* Allow smooth scrolling via JS */
}

/* Responsive adjustments for mobile devices */
@media (max-width: 768px) {
  .fixed-background {
    height: 100vh;
    width: 100%; /* Ensures it spans the entire width */
    background-size: cover; /* Maintains full coverage */
    background-position: center; /* Centers the image */
    background-attachment: scroll; /* Allows scrolling but keeps it visible */
  }
}









  


  