/* App.css */

body {
  margin: 0;
  padding: 0;
  min-height: 100vh; /* Ensure it takes the full viewport height */
  font-family: 'Montserrat', sans-serif;
  background-color: black; /* Set default background color to black */
  color: white; /* Set default text color to white for better contrast */
  overflow-y:auto;
  text-align: center;
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  scroll-snap-type: y mandatory; /* Enable scroll snap */
}


h1 {
  padding-top: 20vh;
  display: block;
  font-size: 3rem;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

h2 {
  display: block;
  font-size: 3rem;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

p {
  font-size: 3vh;
  width: 65%;
  margin: 0 auto;
  scroll-snap-align: start; /* Snap each paragraph */
}

.centered-text {
  font-size: 1.5rem;
  color: white;
  max-width: 75%;
  margin: 0 auto; /* Center text block */
  padding: 0; /* Ensure no additional padding causes overflow */
  box-sizing: border-box; /* Include padding in width calculation */
  z-index: 1000;
  text-align: justify; /* Justify the text evenly */
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;  
  scroll-snap-align: start; /* Snap each block of centered text */
}

.scrollable-content {
  height: 100%; /* Use full height */
  overflow-y: auto; /* Enable vertical scrolling */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  scroll-snap-type: y mandatory; /* Enable scroll snap */
}

body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

@media (max-width: 768px) {
  p {
    font-size: 3.5vw;
    scroll-snap-align: start; /* Snap each paragraph */
    width: 85%;
  }

  .centered-text {
    font-size: 3.5vw;
    scroll-snap-align: start; /* Snap each block of centered text */
  }

  h1 {
    font-size: 7vw;
  }

  h2 {
    font-size: 7vw;
  }
}

